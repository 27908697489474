import React from 'react'
import ArticleBase from "../Article/ArticleBase"
import ArticleHead from "../Article/ArticleHead"
import ArticleTitle from "../Article/ArticleTitle"
import ArticleBody from "../Article/ArticleBody"
import ArticleGrid from "../Article/ArticleGrid"
import ArticleContent from "../Article/ArticleContent"

import ContactPerson from "../Contact/ContactPerson"

const Person = ({id, slug, title, name, content, ...props}) => {

    const phone = "+47 " + props.phone

   return (
        <ArticleBase>
            <ArticleGrid>
                <ArticleHead>
                    <ArticleTitle>{title || name}</ArticleTitle>
                    <ContactPerson {...props} phone={phone} />
                </ArticleHead>

                <ArticleBody>
                    <ArticleContent>{content}</ArticleContent>
                </ArticleBody>
            </ArticleGrid>
        </ArticleBase>        
    )


}

export default Person
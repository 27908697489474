import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Container from '../containers/homepage'
import Person from '../components/Person/Person'

export const query = graphql`
  query PersonTemplateQuery($id: String!) {
    person: sanityPerson(id: { eq: $id }) {
        _type
        id
        name
        phone
        email
        content
        slug {
            current
        }
        color
        backgroundColor {
            hex
        }
    }
  }
`

const ManifestoPage = props => {
    const { data, errors } = props

    if (errors) {
        return (
            <GraphQLErrorList errors={errors} />
        )
    }

    const person = data && data.person

    if (!person) {
        throw new Error(
            'Missing person data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
        )
    }

    return (
        <Container id={person.id} title={person.name}>
            <Person {...person} />
        </Container>
    )


}

export default ManifestoPage

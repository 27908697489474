import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    head: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1em",
        borderBottom: "1px solid",
        marginBottom: "1em"
    }
}));

const ArticleHead = ({children}) => {

    const classes = useStyles()

    return (
        <header className={classes.head}>
            {children}
        </header>
    )

}


ArticleHead.defaultProps = {
}

export default ArticleHead
